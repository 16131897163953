<template>
  <div>
    <KTCodePreview v-bind:title="'Chỉnh sửa nhà cung cấp'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <b-container class="bv-example-row">
            <b-row>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Thông tin nhà cung cấp
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Dành cho cá nhân
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Dành cho ngân hàng
                  </p>
                </div>
              </b-col>
              <b-col md="3">
                <div v-bind:style="textInfo">
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Dành cho doanh nghiệp
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group class="required-control">
                  <label for="input-name">Tên nhà cung cấp:</label>
                  <b-form-input
                    v-model="$v.name.$model"
                    :state="validateState('name')"
                    type="text"
                    size="sm"
                    placeholder="Nhập tên nhà cung cấp"
                    aria-describedby="input-name-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.name.required"
                    id="input-name-live-feedback"
                    >Vui lòng nhập tên nhà cung cấp!</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback
                    v-if="!$v.name.minLength"
                    id="input-name-live-feedback"
                    >Nhập tên với ít nhất 3 kí tự!</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-identity-card">CMND:</label>
                  <b-form-input
                    v-model="identityCard"
                    type="text"
                    size="sm"
                    placeholder="Nhập CMND"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-bank">Ngân hàng:</label>
                  <b-form-input
                    v-model="bank"
                    type="text"
                    size="sm"
                    placeholder="Nhập ngân hàng"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Tên người đại diện:</label>
                  <b-form-input
                    v-model="representative"
                    type="text"
                    size="sm"
                    placeholder="Nhập tên người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group class="required-control">
                  <label>Số điện thoại:</label>
                  <b-form-input
                    v-model="$v.phoneNo.$model"
                    :state="validateState('phoneNo')"
                    size="sm"
                    placeholder="Nhập số điện thoại"
                    aria-describedby="input-mobile-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.phoneNo.required"
                    id="input-mobile-live-feedback"
                    >Vui lòng nhập số điện thoại</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    tabindex="7"
                    v-model="$v.address.$model"
                    :state="validateState('address')"
                    type="text"
                    size="sm"
                    placeholder="Nhập địa chỉ"
                    aria-describedby="input-address-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.address.maxLength"
                    id="input-address-live-feedback"
                    >Địa chỉ không được vượt quá 400 ký
                    tự.</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-branch">Chi nhánh:</label>
                  <b-form-input
                    v-model="bankBranch"
                    type="text"
                    size="sm"
                    placeholder="Nhập chi nhánh"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Vị trí người đại diện:</label>
                  <b-form-input
                    v-model="repPosition"
                    type="text"
                    size="sm"
                    placeholder="Nhập vị trí người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>Email:</label>
                  <b-form-input
                    v-model="$v.email.$model"
                    :state="validateState('email')"
                    type="email"
                    size="sm"
                    placeholder="Nhập email"
                    aria-describedby="input-email-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.email.email"
                    id="input-email-live-feedback"
                    >Vui lòng nhập email hợp lệ!</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-account-no">Số tài khoản:</label>
                  <b-form-input
                    v-model="bankAccountNo"
                    type="text"
                    size="sm"
                    placeholder="Nhập số tài khoản"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Điện thoại người đại diện:</label>
                  <b-form-input
                    v-model="repPhoneNo"
                    type="text"
                    size="sm"
                    placeholder="Nhập điện thoại người đại diện"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group class="required-control">
                  <label>Mã số thuế:</label>
                  <b-form-input
                    size="sm"
                    v-model.trim="$v.taxNo.$model"
                    :state="validateState('taxNo')"
                    required
                    placeholder="Nhập Mã số thuế"
                    class="mb-3"
                    id="taxNo"
                    ref="taxNo"
                    aria-describedby="taxNoError"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    aria-live="assertive"
                    id="taxNoError"
                  >
                    <div v-if="!$v.taxNo.required">
                      Yêu cầu mã số thuế
                    </div>
                    <div v-if="!$v.taxNo.isValidTaxNo">
                      Mã số thuế không hợp lệ
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="3"></b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="input-account-name">Chủ tài khoản:</label>
                  <b-form-input
                    v-model="bankAccountName"
                    type="text"
                    size="sm"
                    placeholder="Nhập chủ tài khoản"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>Loại:</label>
                  <b-form-select
                    class="select-style"
                    v-model="selectedType"
                    :options="typeOptions"
                    style="marginleft: auto"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3"> </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Số ngày thanh toán:</label>
                  <b-form-input
                    v-model="numPaymentDate"
                    type="text"
                    size="sm"
                    placeholder="Nhập số ngày thanh toán"
                    class="text-left"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>Trạng thái:</label>
                  <b-form-select
                    class="select-style"
                    v-model="selectedStatus"
                    :options="statusOptions"
                    style="marginleft: auto"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>

      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onSubmit"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/providers" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
          <!-- </b-col> -->
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';

import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  required,
  minLength,
  email,
  maxLength,
} from 'vuelidate/lib/validators';
import { isValidTaxNo } from '@/utils/validation';
import { makeToastFaile } from '@/utils/common';
import { SUCCESS_RESPONSE_STATUS } from '@/utils/constants';

const KTCodePreview = () =>
  import(/* webpackPrefetch: true */ '@/view/content/CodePreview.vue');

export default {
  mixins: [validationMixin],
  data() {
    return {
      name: '',
      phoneNo: '',
      email: '',
      taxNo: '',
      identityCard: '',
      address: '',
      bank: '',
      bankBranch: '',
      bankAccountNo: '',
      bankAccountName: '',
      representative: '',
      repPosition: '',
      repPhoneNo: '',
      status: 1,
      textInfo: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '15px',
      },
      selectedStatus: null,
      statusOptions: [
        { value: null, text: 'Trạng thái' },
        { value: '1', text: 'Đang giao dịch' },
        { value: '2', text: 'Ngưng giao dịch' },
      ],
      selectedType: null,
      typeOptions: [
        { value: null, text: 'Loại nhà cung cấp' },
        { value: '1', text: 'Cá nhân' },
        { value: '2', text: 'Doanh nghiệp' },
      ],
      id: '',
      numPaymentDate: 0,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhà cung cấp', route: '/providers' },
      { title: 'Danh sách nhà cung cấp', route: '/providers' },
      { title: 'Cập nhật nhà cung cấp' },
    ]);
    this.id = this.$route.query.id;
    this.getProviderById();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getProviderById: async function() {
      ApiService.setHeader();
      ApiService.query('providers' + '/' + this.id).then((response) => {
        this.id = response.data.data.provider.id;
        this.name = response.data.data.provider.name;
        this.phoneNo = response.data.data.provider.phoneNo;
        this.email = response.data.data.provider.email;
        this.taxNo = response.data.data.provider.taxNo;
        this.identityCard = response.data.data.provider.identityCard;
        this.address = response.data.data.provider.address;
        this.bank = response.data.data.provider.bank;
        this.bankBranch = response.data.data.provider.bankBranch;
        this.bankAccountNo = response.data.data.provider.bankAccountNo;
        this.bankAccountName = response.data.data.provider.bankAccountName;
        this.representative = response.data.data.provider.representative;
        this.repPosition = response.data.data.provider.repPosition;
        this.repPhoneNo = response.data.data.provider.repPhoneNo;
        this.selectedStatus = response.data.data.provider.status;
        this.selectedType = response.data.data.provider.type;
        this.numPaymentDate = response.data.data.provider.numPaymentDate;
      });
    },
    onSubmit: async function() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const data = {
        id: this.id,
        name: this.$v.name.$model,
        phoneNo: this.$v.phoneNo.$model,
        email: this.$v.email.$model,
        taxNo: this.$v.taxNo.$model,
        type: this.selectedType,
        identityCard: this.identityCard,
        address: this.$v.address.$model,
        bank: this.bank,
        bankBranch: this.bankBranch,
        bankAccountNo: this.bankAccountNo,
        bankAccountName: this.bankAccountName,
        representative: this.representative,
        repPosition: this.repPosition,
        repPhoneNo: this.repPhoneNo,
        status: this.selectedStatus,
        numPaymentDate: this.numPaymentDate,
      };

      ApiService.post('providers' + '/' + this.id, data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === SUCCESS_RESPONSE_STATUS) {
            this.$router.push({
              name: 'list-provider',
              params: { updateProviderSuccess: true, message: message },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          makeToastFaile(error.response.data.message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    email: { email },
    taxNo: {
      required,
      isValidTaxNo,
    },
    address: { maxLength: maxLength(400) },
    phoneNo: {
      required,
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
